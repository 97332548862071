<template>
  <div>
    <div class="rows" :dir="$t('clan')=='ar'?'rtl':'ltr'">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$t('Users')}}</h3>
            </div>
            <span class="add" v-if="type === 'admin'" >
              <router-link to="/admin/users/new-user">
                <b-button variant="warning"
                  ><i class="fas fa-plus-circle text-white mr-2" v-if="$t('clan')=='en'"></i
                  >
                  <i class="fas fa-plus-circle text-white ml-2" v-else></i
                  >
                  {{$t('New')}}</b-button
                >
              </router-link>
            </span>
          </div>
          <div class="card-body">
            <div>
              <UsersTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";

import UsersTable from "./UsersTable";
export default {
  components: {
    UsersTable,
  },
  mounted: function () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: "/admin/users" },
    ]);

    this.$store.dispatch(VERIFY_AUTH).then(res =>{
      var data = res.data[0]
      console.log(data.type)
      this.type = data.type
      });


    //Loads in layout to reduce the delay
  },
  data(){
    return{
      type:''
    }
  },
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}
</style>