<template>
  <div>
    <div>
          <div class="form-group">
                    <div class="input-icon input-icon-right">
                      <input
                        type="text"
                        class="form-control"
                        @input="search"
                        v-model="filter"
                        placeholder="Search by name or phone"
                      />
                      <span @click="search"
                        ><i class="flaticon2-search-1 icon-md"></i
                      ></span>
                    </div>
                  </div>
      <b-table
        :items="table.items"
        :fields="$t('clan')=='en'?table.fields:tableAR.fields"
        :responsive="true"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
      >
      <template #empty>
          <div v-if="isBusy" class="text-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              class="spinnerClass"
            ></b-spinner>
          </div>
          <p v-else class="text-center muted mb-0 mt-2" style="font-size: 13px">
            {{ $t("There are no records to show") }}
          </p>
        </template>

        <template #cell(actions)="row">
          <div class="">
            <button @click.prevent="ViewUser(row.item.id)" class="" v-if="type === 'admin'">
              <i class="far fas fa-eye"></i>
              {{$t('view')}}
            </button>
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <!--<b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="left"
        size="lg"
        class="my-0"
        @click.native="getData"
      ></b-pagination>-->
      <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link"  @click="prev">{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link"  @click="next">{{$t('Next')}}</a></li>
  </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";


export default {
  name: "UsersTable",
  data: function () {
    return {
      isBusy:'',
      type:'',
      filter:'',
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage:1,
      lastPage:''
    };
  },

  computed: {
    ...mapGetters({ table: "users/table" }),
    ...mapGetters({ tableAR: "users/tableAR" }),
  },
  async mounted() {
    this.isBusy = true
    await this.$store.dispatch("users/loadAll").then((res) => {
      //console.log(res.data.meta)
      this.lastPage = res.data.meta.last_page
      this.totalRows = res.data.meta.total;
    });
    
    this.$store.dispatch(VERIFY_AUTH).then(res =>{
      var data = res.data[0]
      console.log(data.type)
      this.type = data.type
      });

    this.isBusy = false
  },

  methods: {search(){
      this.$store.dispatch("users/search", this.filter).then((res)=>{
        //console.log(res)
        this.currentPage = res.data.current_page
        this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      });
    },
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async getData() {
      this.isBusy = true
      await this.$store.dispatch("users/loadPage", this.currentPage);
      this.isBusy = false
    },
    ViewUser(id) {
      this.$router.push({ name: "ViewUser", params: { id: id } });
    },
  },
};
</script>
<style lang="css" scoped>
</style>